<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          sm="12"
          md="12"
          lg="6"
        >
          <h2>
            Modification d'un manuel d'utilisation
          </h2>
        </b-col>
        <b-col
          sm="12"
          md="12"
          lg="6"
          class="text-right"
        >
          <b-button
            variant="outline-primary"
            @click="$router.push({ name: 'manuel-utilisation-details' })"
          >
            <feather-icon
              icon="ArrowLeftIcon"
              class="mr-25"
            />
            <span>Retour aux détails</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <validation-observer ref="validationForm">
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Titre du manuel d'utilisation"
              label-cols-md="12"
              label-for="title"
            >
              <validation-provider
                v-slot="{ errors }"
                rules="required"
              >
                <b-form-input
                  id="title"
                  v-model="form.title"
                  placeholder="Insérer le titre du manuel d'utilisation"
                  type="text"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
          >
            <b-form-group
              label="Profil concerné"
              label-cols-md="12"
              label-for="profil"
            >
              <validation-provider
                v-slot="{ errors }"
                rules="required"
              >
                <v-select
                  id="profil"
                  v-model="form.role_ids"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="listRoles"
                  :reduce="option => option.id"
                  label="title"
                  placeholder="Sélectionner le profil concerné"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Description du manuel d'utilisation"
              label-cols-md="12"
              label-for="descripion"
            >
              <validation-provider
                v-slot="{ errors }"
                rules="required"
              >
                <vue-editor
                  v-model="form.description"
                  placeholder="Insérer la description du manuel d'utilisation"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
      <b-row>
        <b-col
          sm="12"
          md="12"
          lg="12"
          class="text-right"
        >
          <b-button
            v-if="$can('edit', 'manuel_utilisation')"
            variant="primary"
            :disabled="isLoading"
            class="col-sm-12 col-md-2 col-lg-2"
            @click="updateManuel()"
          >
            <b-spinner
              v-if="isLoading"
              small
              class="mr-25"
            />
            <feather-icon
              v-else
              icon="EditIcon"
              class="mr-25"
            />
            <span>Modifier</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { VueEditor } from 'vue2-editor'

import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

// eslint-disable-next-line import/no-cycle
import manuelUtilisations from '@/store/manuel-utilisations'
// eslint-disable-next-line import/no-cycle
import rolesStoreModule from '@/store/roles'

// eslint-disable-next-line import/no-cycle
import {
  registerStoreModule,
  // unregisterStoreModule,
} from '@/helpers/vuex-utils'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BSpinner,
    vSelect,
    VueEditor,
    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    const requiredStoreModules = [
      { path: 'roles', module: rolesStoreModule },
      { path: 'manuel-utilisations', module: manuelUtilisations },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)
    return {
      requiredStoreModules,
    }
  },
  data() {
    return {
      isLoading: false,
      filteredOptions: [],
      listRoles: [],
      updateId: null,
      form: {
        title: null,
        role_ids: null,
        description: null,
      },
    }
  },

  watch: {
    $route: {
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      async handler(val, old) {
        // eslint-disable-next-line radix
        this.updateId = parseInt(val.params.id)
        if (this.updateId !== null) {
          await this.loadUpdateData()
        }
        await this.loadRoles()
      },
    },
  },

  // beforeDestroy() {
  //   unregisterStoreModule(this.requiredStoreModules)
  // },

  methods: {
    ...mapActions('roles', {
      action_fetchRoles: 'fetchRoles',
    }),
    ...mapActions('manuel-utilisations', {
      action_getManuelUtilisation: 'getManuelUtilisation',
      action_updateManuelUtilisation: 'updateManuelUtilisation',
    }),
    async loadRoles() {
      try {
        const response = await this.action_fetchRoles()
        if (response.status === 200) {
          this.listRoles = response.data.data
        } else {
          console.log('La réponse n\'a pas un status HTTP 200')
        }
      } catch (error) {
        console.log('Erreur lors du chargement des rôles', error)
      }
    },
    async loadUpdateData() {
      try {
        const response = await this.action_getManuelUtilisation(this.updateId)
        if (response.status === 200) {
          this.updateData = response.data.data
          this.form.title = this.updateData.title
          this.form.description = this.updateData.description
          this.form.role_ids = this.updateData.roles[0].id
        } else {
          console.log("La réponse n'a pas un status HTTP 200")
        }
      } catch (error) {
        console.log("Erreur lors du chargement des informations du manuel d'utilisation", error)
      }
    },
    async updateManuel() {
      this.$refs.validationForm.validate().then(async success => {
        if (success) {
          this.isLoading = true
          const roleIds = []
          roleIds.push(this.form.role_ids)
          const data = {
            title: this.form.title,
            role_ids: roleIds,
            description: this.form.description,
          }
          try {
            // eslint-disable-next-line no-unused-vars
            const response = await this.action_updateManuelUtilisation({ id: this.updateId, data })
            if (response.status === 202) {
              this.isLoading = false
              this.$router.push({ name: 'manuel-utilisation-details', params: { id: this.updateId } })
              await this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Modification réussie',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                },
              })
            } else {
              console.log("La réponse n'a pas un status HTTP 202")
            }
          } catch (error) {
            this.isLoading = false
            console.log("Erreur lors de la modification du manuel d'utilisation", error)
            await this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Une erreur est survenue',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: '',
              },
            })
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Champs requis',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
    },

  },
}
</script>
